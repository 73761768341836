/** @format */

import React, { Fragment, Suspense, lazy } from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import MobileLayout from "./Components/MobileLayout/index.";
import "../App/Assets/css/general.scss";
import "../App/Assets/css/style.scss";
const routes = [
  {
    path: "/",
    exact: true,
    Component: lazy(() => import("./Screens/Home")),
    header: true,
  },
  {
    path: "/voter-list",
    exact: true,
    Component: lazy(() => import("./Screens/VoterList")),
    name: "Voter List",
    header: true,
  },
  {
    path: "/elastic-search-list",
    exact: true,
    Component: lazy(() => import("./Screens/ElasticSearchVoters")),
    name: "Voter List",
    header: true,
  },
  {
    path: "/villagewise-list",
    exact: true,
    Component: lazy(() => import("./Screens/VillageWise")),
    name: "VIllage Wise List",
    header: true,
  },
  {
    path: "/namewise-list",
    exact: true,
    Component: lazy(() => import("./Screens/ElasticSearchVoters")),
    name: "Name Wise List",
    header: true,
  },
  {
    path: "/birthday-list",
    exact: true,
    Component: lazy(() => import("./Screens/BirthdayList")),
    name: "Birthday List",
    header: true,
  },
  {
    path: "/boothwise-list",
    exact: true,
    Component: lazy(() => import("./Screens/BoothwiseList")),
    name: "Booth Wise List",
    header: true,
  },
  {
    path: "/mobile-number-list",
    exact: true,
    Component: lazy(() => import("./Screens/MobilenumberwiseList")),
    name: "Mobile No. List",
    header: true,
  },
  // {
  //   path: "/age-wise-list",
  //   exact: true,
  //   Component: lazy(() => import("./Screens/AgewiseList")),
  //   name: "Agewise List",
  //   header: true,
  // },
  {
    path: "/religion-wise-list",
    exact: true,
    Component: lazy(() => import("./Screens/ReligionWiseList")),
    name: "Religion Wise List",
    header: true,
  },
  {
    path: "/mandal-wise-list",
    exact: true,
    Component: lazy(() => import("./Screens/MandalWiseList")),
    name: "Mandal Wise List",
    header: true,
  },
  {
    path: "/community-wise-list",
    exact: true,
    Component: lazy(() => import("./Screens/CommunityWiseList")),
    name: "Community Wise List",
    header: true,
  },
  {
    path: "/caste-wise-list",
    exact: true,
    Component: lazy(() => import("./Screens/CastewiseList")),
    name: "Cast Wise List",
    header: true,
  },
  {
    path: "/door-wise-list",
    exact: true,
    Component: lazy(() => import("./Screens/DoorNoWiseList")),
    name: "Door Number Wise List",
    header: true,
  },
  {
    path: "/section-wise-list",
    exact: true,
    Component: lazy(() => import("./Screens/SectionwiseList")),
    name: "Section Wise List",
    header: true,
  },
  {
    path: "/communication-management",
    exact: true,
    Component: lazy(() => import("./Screens/CommunicationManagement")),
    name: "Communication Management",
    header: true,
  },

  {
    path: "/add-voter",
    exact: true,
    Component: lazy(() => import("./Screens/AddVoterNew")),
    name: "Add Voter",
    header: true,
  },
  {
    path: "/add-voter-new",
    exact: true,
    Component: lazy(() => import("./Screens/AddVoterCustom")),
    name: "Add Voter",
    header: true,
  },
  {
    path: "/edit-voter-new/:id",
    exact: true,
    Component: lazy(() => import("./Screens/AddVoterCustom")),
    name: "Edit Voter",
    header: true,
  },
  {
    path: "/edit-voter/:id",
    exact: true,
    Component: lazy(() => import("./Screens/AddVoterNew")),
    name: "Edit Voter",
    header: true,
  },
  {
    path: "/login",
    exact: true,
    Component: lazy(() => import("./Screens/Login")),
    header: false,
  },
  {
    path: "/filtered-list/:searchBy/:id/:FilterName",
    exact: true,
    Component: lazy(() => import("./Screens/FilteredVoterList")),
    name: "Voter List",
    header: true,
  },
  {
    path: "/campaign-list",
    exact: true,
    Component: lazy(() => import("./Screens/Campaign/CampaignList")),
    name: "Campaign List",
    header: true,
  },
  {
    path: "/view-profile/:Id",
    exact: true,
    Component: lazy(() => import("./Screens/ViewProfile")),
    name: "Profile",
    header: true,
  },
  {
    path: "/advertisement-card",
    exact: true,
    // Component: lazy(() => import("./Screens/ElectionCard")),
    Component: lazy(() => import("./Screens/Campaign/CampaignList")),
    name: "Advertisement",
    header: true,
  },
  {
    path: "/activity-list",
    exact: true,
    Component: lazy(() => import("./Screens/ActivityList")),
    name: "Activity List",
    header: true,
  },
  {
    path: "/activity-list/:agentId",
    exact: true,
    Component: lazy(() => import("./Screens/ActivityList")),
    name: "Activity List",
    header: true,
  },
  {
    path: "/agents-activity-list",
    exact: true,
    Component: lazy(() => import("./Screens/AgentsActivityList")),
    name: "Agent Activity List",
    header: true,
  },
  {
    path: "/add-new-registration",
    exact: true,
    Component: lazy(() =>
      import("./Screens/UnregisteredVoters/addUnregisteredVoters")
    ),
    name: "Add New Registration",
    header: true,
  },
  {
    path: "/send-message",
    exact: true,
    Component: lazy(() => import("./Screens/SendMessage")),
    name: "Send Message",
    header: true,
  },
  {
    path: "/exit-poll",
    exact: true,
    Component: lazy(() => import("./Screens/ExitPoll")),
    name: "Exit Poll",
    header: true,
  },
  {
    path: "/web-campaign-list",
    exact: true,
    Component: lazy(() => import("./Screens/Campaign/webCampaign")),
    name: "Campaign List",
    header: true,
  },
  {
    path: "/campaigns/:id",
    exact: true,
    Component: lazy(() => import("./Screens/Campaign/campaignDetails")),
    name: "Campaign Details",
    header: true,
  },
];

class App extends React.Component {
  render() {
    return (
      <Fragment>
        <Helmet titleTemplate="Election App" title="Title" />
        <Route exact path="/home" render={() => <Redirect to="/" />} />
        {routes.map(({ path, Component, exact, name, header }) => (
          <Route
            path={path}
            key={path}
            exact={exact}
            render={() => {
              return (
                <Suspense fallback={null}>
                  <MobileLayout footer={false} name={name} header={header}>
                    <Component />
                  </MobileLayout>
                </Suspense>
              );
            }}
          />
        ))}
      </Fragment>
    );
  }
}

export default withRouter(App);
