import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { setPrimaryColor } from "../../../global/setPrimaryColor";

const mapStateToProps = ({ user, dispatch, settings, app }) => ({
  dispatch,
  user,
  settings,
  app,
});

const fabStyle = {
  position: "absolute",
  bottom: 16,
  right: 16,
  bgcolor: setPrimaryColor()?.primaryColor,
};

class Footer extends Component {
  handleIcon = (e) => {
    this.props.setCurrentTab(e);
  };
  render() {
    return (
      <React.Fragment>
        <div className="w-100 fixed-bottom container-fluid bg-white py-2">
          Footer
        </div>
        <Link to="/voter-list">
          {" "}
          <Fab
            variant="extended"
            sx={fabStyle}
            size="large"
            color="secondary"
            aria-label="add"
          >
            <AddIcon />
            Add Voter
          </Fab>
        </Link>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(Footer);
