import React from "react";
import Header from "../Header";
import Footer from "../Footer";

export const MobileLayout = (props) => {
  return (
    <React.Fragment>
      <div style={{ height: "100vh", maxWidth: "500px", margin: "auto" }}>
        {props.header === false ? (
          ""
        ) : (
          <>
            <Header transparent={props.transparent} name={props.name}></Header>
          </>
        )}
        <div
          className={props.header ? "th-95-height-scroll" : ""}
          style={{
            paddingTop: `${props.header ? "60px" : ""}`,
            paddingBottom: `20px`,
            backgroundColor: "#ffffff",
            height: "calc(98vh)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            zIndex: 1,
            transition: "all 1s ease-in-out",
          }}
        >
          <div className={props.header ? "py-2 th-bg-white" : ""}>
            {props.loading ? (
              <div className="th-ht-100 col-12 d-flex justify-content-center align-items-center">
                <div className="" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              props.children
            )}
          </div>
        </div>

        {props.footer === false ? "" : <Footer />}
      </div>
    </React.Fragment>
  );
};

export default MobileLayout;
