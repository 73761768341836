import React, { useEffect } from "react";
import App from "./App/index.js";
import { setPrimaryColor } from "./global/setPrimaryColor.js";

const Router = ({ history }) => {
  useEffect(() => {
    setPrimaryColor();
  }, []);

  return <App />;
};

export default Router;
