import React, { useState, useEffect } from "react";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { Link, withRouter } from "react-router-dom";
import { setPrimaryColor } from "./setPrimaryColor";

const AddVoterBtn = (props) => {
  const fabStyle = {
    position: "absolute",
    top: 10,
    right: window.innerWidth < 600 ? (props.match.url === "/" ? 66 : 16) : 520,
    bgcolor: setPrimaryColor()?.primaryColor,
    boxShadow: 0,
    borderRadius: 2,
  };

  return (
    <Link to="/add-voter">
      <Fab
        variant="extended"
        sx={fabStyle}
        size="small"
        color="secondary"
        aria-label="add"
        className="position-fixed th-12"
      >
        <AddIcon />
        Add Voter
      </Fab>
    </Link>
  );
};

export default withRouter(AddVoterBtn);
