/** @format */

import axios from "axios";
import setAuthToken from "../global/setAuthToken";
import store from "store";

export async function login(email1, password1) {
  const value = { username: email1, password: password1 };

  return axios
    .post(`/token-auth/`, value, {
      headers: {
        Authorization: "",
        "TENANT-ID": localStorage.getItem("route"),
      },
    })
    .then((response) => {
      if (response.status === 200) {
        localStorage.setItem("token", response.data.token);
        setAuthToken(response.data.token);
        store.set("user", response.data.user.id);
        return response.data;
      } else if (response.status !== 200) {
        return false;
      } else {
        return false;
      }
    })
    .catch((error) => {
      return false;
    });
}

export async function otpLogin(contact1, password1) {
  return axios
    .get(`/otp/verify-otp/?otp=${password1}&contact=${contact1}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
    })
    .then((response) => {
      console.log(response, "response ");
      if (response.status === 200) {
        localStorage.setItem("token", response.data.token);
        setAuthToken(response.data.token);
        store.set("user", response.data.user.id);
        return response.data;
      } else if (response.status !== 200) {
        return false;
      } else {
        return false;
      }
    })
    .catch((error) => {
      return false;
    });
}

export async function currentAccount() {
  let authorized1 = false;
  let url;
  // if (localStorage.getItem('route') === 'electiondemoapi') {
  url = "https://apielection1.trixno.com/apiV1/current-user/";
  // } else {
  //   url = `https://${localStorage.getItem(
  //     'route',
  //   )}.trixno.com/apiV1/current-user/`
  // }
  return axios
    .get(url, {
      headers: {
        "Content-Type": "application/json",
        "TENANT-ID": localStorage.getItem("route"),
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    })
    .then((response) => {
      authorized1 = true;

      const { data } = response;
      return {
        id: data.id,
        authorized: authorized1,
        username: data.username,
        role: data.roles,

        gender_choice: data.gender_choice,
        email: data.email,
        contact: data.contact,
        name: data.name,
        token: localStorage.getItem("token"),
        avatar: data.avatar,
        clinic: data.owner,
        extra_details: data.user_extra_details,
        city: data.city,
        polling_booth: data.polling_booth,
      };
    })
    .catch((error) => {});
}

export async function logout() {
  localStorage.clear();
  // window.location.reload();
}
