import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "./actions";

export function* GET_SETTINGS({ payload }) {
  yield put({
    type: "settings/SET_STATE",
    payload: {},
  });
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_SETTINGS, GET_SETTINGS)]);
}
