import { all, takeEvery, put, call } from "redux-saga/effects";
import { history } from "../../index";
import { login, currentAccount, logout, otpLogin } from "../../services/users";
import actions from "./actions";

export function* LOGIN({ payload }) {
  const { email, password } = payload;
  yield put({
    type: "user/SET_STATE",
    payload: {
      loading: true,
      wrong: false,
    },
  });
  const success = yield call(login, email, password);
  if (success) {
    yield put({
      type: "user/LOAD_CURRENT_ACCOUNT",
    });
  } else {
    yield put({
      type: "user/SET_STATE",
      payload: {
        loading: false,
        wrong: true,
        login: true,
      },
    });
  }
}

export function* OTPLOGIN({ payload }) {
  const { contact, password } = payload;
  yield put({
    type: "user/SET_STATE",
    payload: {
      loading: true,
      wrong: false,
    },
  });
  const success = yield call(otpLogin, contact, password);
  if (success) {
    yield put({
      type: "user/LOAD_CURRENT_ACCOUNT",
    });
  } else {
    yield put({
      type: "user/SET_STATE",
      payload: {
        loading: false,
        wrong: true,
        login: true,
      },
    });
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: "settings/GET_SETTINGS",
  });
  yield put({
    type: "user/SET_STATE",
    payload: {
      loading: true,
    },
  });
  const response = yield call(currentAccount);
  if (response) {
    const {
      id,
      contact,
      email,
      // photoURL: avatar,
      avatar,
      role,
      authorized,
      name,
      userdata,
      token,
      gender_choice,
      city,
      polling_booth,
    } = response;
    yield put({
      type: "user/SET_STATE",
      payload: {
        id,
        contact,
        email,
        gender_choice,
        role,
        authorized,
        name,
        userdata,
        token,
        avatar,
        city,
        polling_booth,
      },
    });
    // history.push("/home");
  }
  yield put({
    type: "user/SET_STATE",
    payload: {
      loading: false,
      login: false,
    },
  });
}

export function* LOGOUT() {
  yield call(logout);
  yield history.push("/");
  yield put({
    type: "user/SET_STATE",
    payload: {
      id: "",
      name: "",
      role: "",
      contact: "",
      email: "",
      avatar: "",
      authorized: false,
      loading: false,
      userdata: {},
      wrong: false,
      login: false,
      gender_choice: "",
      city: "",
      token: "",
      user: "",
    },
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.OTPLOGIN, OTPLOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ]);
}
