import Logo from "../../src/App/Assets/img/logo.png";
import YcpLogo from "../../src/App/Assets/img/ycpLogo.jpeg";
export const setPrimaryColor = () => {
  const currentSubdomain = window.location.host.split(".")[0];

  let primaryColor, secondaryColor;
  let logo = Logo;

  switch (currentSubdomain) {
    case "trs":
      primaryColor = "#ed008c";
      secondaryColor = "#ed008c40";
      logo = Logo;
      break;
    case "ycp":
      primaryColor = "#009c47";
      secondaryColor = "#009c4740";
      logo = "https://doctodo-lab.s3.ap-south-1.amazonaws.com/ycpLogo.jpeg";
      break;
    default:
      primaryColor = "#f87e58"; // Default color
      secondaryColor = "#f87e5840";
      logo = Logo;
      break;
  }

  document.documentElement.style.setProperty("--primary-color", primaryColor);
  document.documentElement.style.setProperty(
    "--secondary-color",
    secondaryColor
  );
  return { primaryColor, currentSubdomain, logo };
};
