/** @format */

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Logo from "../../../App/Assets/img/logo.png";
import LogoWhite from "../../../App/Assets/img/logo.png";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { IconButton } from "@mui/material";
import { ExitToApp } from "@mui/icons-material";
import AddVoterBtn from "../../../global/addVoterBtn";
import { setPrimaryColor } from "../../../global/setPrimaryColor";
const mapStateToProps = ({ dispatch, user }) => ({
  dispatch,
  user,
});

class Header extends Component {
  state = {
    drawerOpen: false,
    isDropDown: false,

    loadingLanguage: true,
    languageData: [],
  };

  handleLanguage = () => {
    this.setState({ isDropDown: !this.state.isDropDown });
  };

  handleLogout = () => {
    this.props.dispatch({
      type: "APP_LOGOUT",
    });
    this.props.dispatch({
      type: "user/LOGOUT",
    });
  };

  render() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("platform") == "app") {
      localStorage.setItem("platform", "app");
    }
    return (
      <React.Fragment>
        {/* <MyMobile> */}
        {window.location.href.includes("add-voter") ||
        window.location.href.includes("edit-voter") ||
        window.location.href.includes("campaign-list") ||
        window.location.href.includes("communication-management") ||
        window.location.href.includes("filtered-list") ||
        window.location.href.includes("view-profile") ||
        window.location.href.includes("advertisement-card") ||
        window.location.href.includes("exit-poll") ||
        window.location.href.includes("campaigns") ||
        this.props.match.url === "/" ||
        this.props.user.role.id == 6 ? null : (
          <AddVoterBtn />
        )}
        <div
          className={"fixed-top th-bg-white m-auto"}
          style={{ maxWidth: "500px" }}
        >
          {!this.props.name ? (
            <div className="container py-1">
              <div className="d-flex align-items-center justify-content-between">
                <div className=" py-2 th-bg-white">
                  <div className="my-auto">
                    <Link to="/">
                      <img
                        alt="Election Logo"
                        className="my-auto"
                        src={setPrimaryColor()?.logo}
                        style={{
                          height: 30,
                        }}
                      />
                    </Link>
                  </div>
                </div>
                <IconButton className="th-32" onClick={this.handleLogout}>
                  <ExitToApp className="th-primary" />
                </IconButton>
              </div>
            </div>
          ) : (
            <div
              className="col-12 th-18 pt-3 th-primary th-fw-600 text-uppercase th-bg-white py-2 m-auto"
              style={{ maxWidth: "500px" }}
            >
              <ArrowBackIosNewOutlinedIcon
                onClick={() => {
                  if (
                    window.location.pathname.includes("/namewise-list") ||
                    window.location.pathname.includes("/elastic-search-list")
                  ) {
                    sessionStorage.removeItem("lastSearch");
                  }
                  window.location.pathname.includes("/activity-list")
                    ? this.props.history.push("/")
                    : window.location.pathname.includes("/view-profile")
                    ? this.props.history.push("/namewise-list")
                    : window.location.pathname.includes("/namewise-list")
                    ? this.props.history.push("/")
                    : this.props.history.goBack();
                }}
              />{" "}
              <span className="col-12 pl-2 th-16 th-primary ">
                {" "}
                {this.props.name}
              </span>
            </div>
          )}
        </div>
        {/* </MyMobile> */}
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(withRouter(Header));
