/** @format */

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import Router from "./routes";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import Axios from "axios";
import { createBrowserHistory } from "history";
import reducers from "./redux/reducers";
import sagas from "./redux/sagas";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "connected-react-router";
// if (localStorage.getItem("route") === "electiondemoapi") {
Axios.defaults.baseURL = `https://apielection1.trixno.com/`;
// } else {
//   Axios.defaults.baseURL = localStorage.getItem("route")
//     ? `https://${localStorage.getItem("route")}.trixno.com/`
//     : "https://apielection1.trixno.com/";
// }
// Axios.defaults.baseURL = 'https://electiondemoapi.justappoint.com/'
Axios.defaults.headers.common.Authorization = localStorage["token"]
  ? `JWT ${localStorage.getItem("token")}`
  : "";
Axios.defaults.headers.post["Content-Type"] = "application/json";
Axios.defaults.headers["TENANT-ID"] = localStorage.getItem("route");
const history = createBrowserHistory();

// middlewared

const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [sagaMiddleware, routeMiddleware];
// if (process.env.NODE_ENV === 'development') {
//   middlewares.push(logger)
// }
const store = createStore(
  reducers(history),
  compose(applyMiddleware(...middlewares))
);
sagaMiddleware.run(sagas);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Router history={history} />
    </BrowserRouter>
  </Provider>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
export { history };
