import axios from "axios";

export default function setAuthToken(token) {
  // axios.defaults.headers.common.Authorization = "";
  // delete axios.defaults.headers.common.Authorization;

  axios.defaults.headers.common.Authorization = token
    ? `JWT ${token}`
    : localStorage["token"]
    ? `JWT ${localStorage.getItem("token")}`
    : "";
}
